.homepage_Body {
    background-image: url("../Homepage_Icons/vegas_skyline.png"), linear-gradient(to top, #EFCF05, #FC575E);;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    

    animation-name: vegasBackground1;
    animation-duration: 90s;
    animation-delay: 0s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes vegasBackground1 {
    0% {
        background-position: 0;
    }

    50% {
        background-position: 100%;
    }
 
    100% {
        background-position: 0%;
    }
}