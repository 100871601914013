/* Making the video modal reactive */
@media (max-width: 1050px) {
    .modal_Background .modal_Container {
        width: 60em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 55em;
        height: 35.4em;
    }
}

@media (max-width: 950px) {
    .modal_Background .modal_Container {
        width: 55em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 50em;
        height: 34.4em;
    }
}

@media (max-width: 850px) {
    .modal_Background .modal_Container {
        width: 50em;
        height: 39em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 45em;
        height: 33.4em;
    }
}


@media (max-width: 800px) {
    .modal_Background .modal_Container {
        width: 45em;
        height: 38em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 40em;
        height: 32.4em;
    }
}

@media (max-width: 700px) {
    .modal_Background .modal_Container {
        width: 40em;
        height: 37em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 35em;
        height: 31.4em;
    }
}

@media (max-width: 650px) {
    .modal_Background .modal_Container {
        width: 35em;
        height: 36em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 30em;
        height: 30.4em;
    }
}


@media (max-width: 550px) {
    .modal_Background .modal_Container {
        width: 30em;
        height: 35em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 25em;
        height: 29.4em;
    }
}


@media (max-width: 450px) {
    .modal_Background .modal_Container {
        width: 25em;
        height: 30em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 20em;
        height: 28.4em;
    }
}


@media (max-width: 350px) {
    .modal_Background .modal_Container {
        width: 20em;
        height: 25em;
    }

    .modal_Background .modal_Container .modal_Body iframe {
        width: 15em;
        height: 27.4em;
    }
}