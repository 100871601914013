.menu_bar {
    font-size: 1.5rem;
    width: 100%;
    top: 0rem;
    position: fixed;
    background-color: white;
    z-index: 2;
}


.menu_bar ul{ 
    margin: auto;
    border-style: solid;
    border-color: black;
    border-width: 0px 0px 2px 0px;
    text-align: right;
    padding: 0.6rem;
}

.menu_bar li {
    display: inline;
    margin: 1.25rem;
}


/* Menu bar user interactions */
.menu_bar a:link, a:visited {
    color: black;
    text-decoration: none;
}

.menu_bar li:hover {
    cursor: pointer;
    text-decoration: underline;
}

.menu_bar li:active {
    text-decoration: underline;
}


@media (max-width: 500px) {
    .menu_bar li {
        display: inline;
        margin: 0.8rem;
    }
}