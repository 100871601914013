.modal_Background {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.modal_Background .modal_Container {
    width: 65em;
    height: 40em;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 2.5em;
}

/* Header of the modal */
.modal_Background .modal_Container .modal_Title {
    display: inline-block;
    text-align: center;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 10px;
    background-color: #4F5B67;
    color: white;
    border-radius: 12px 12px 0px 0px;
}

.modal_Background .modal_Container .modal_Title .modal_Title_Close_Button_Container {
    float: right;
}

.modal_Background .modal_Container .modal_Title .modal_Title_Close_Button_Container .modal_Title_Close_Button {
    font-size: 32px;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
}

.modal_Background .modal_Container .modal_Title .modal_Title_Close_Button_Container .modal_Title_Close_Button:hover {
    color: red;
}

.modal_Background .modal_Container .modal_Title h1 {
    font-size: 32px;
}

/* Body of the modal */
.modal_Background .modal_Container .modal_Body {
    padding-top: 1em;
    padding-bottom: 1.5em;
    background-color: #C1C5CE;
    border-radius: 0px 0px 12px 12px;
}

.modal_Background .modal_Container .modal_Body iframe {
    width: 60em;
    height: 36.4em;
    padding-left: 30px;
    padding-right: 30px;
}


