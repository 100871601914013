.project_Body {
    background-image: linear-gradient(to right, #cc5333, #c94b4b);   
}

#demoInfo {
    text-align: center;
    font-size: 1em;
    padding-top: 4em;
    color: white;
}

/* Gird for all of the project cards */
.project-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: repeat(auto, 1fr);
    grid-gap: 3rem;
    text-align: center;
    padding: 1rem 1.2rem;
}

/* Each individual project card */
.project-grid .project {
    border: 3px solid black; 
    border-radius: 20px;
    height: 30em;
    background-color: white;
    overflow: hidden;
}


.project-grid .project .project_Card {
    position: relative;
    width: 100%;
    height: 200%;
    background-color: #28A744;
    transition: 0.5s;
}

.project-grid .project .project_Card:hover {
    transform: translateY(-50%);
}


.project-grid .project .project_Card .thumbnail {
    width: 100%;
    height: 50%;
    position: relative;
    display: flex;
    justify-content: center;
}



/* Thumbnail image for each project */
.project-grid .project .project_Card .project_image {
    /* border: 3px solid black; */
    /* border-radius: 20px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Header at the top of each project card */
.project-grid .project h2 {
    font-size: 2.5em;
    background-color: rgba(0, 0, 0, 0.6);
    font-family: system-ui;
    color: white;
    width: 90%;
    height: 10%;
    position: absolute;
    bottom: 1em;
}

.project-grid .project .tooMuchText {
    font-size: 1.6em;
}

.project-grid .project .project_Card .project_Information {
    position: absolute;
    width: 100%;
    height: 50%;
    background-color: #283E51;
    top: 50%;
}



.hide {
    display: none;
}




/*The word 'Description' on each project card */
.project-grid .project .project_description h3 {
    font-size: 2em;
    font-weight: normal;
    margin-top: 1em;
}

/* Description of each project */
.project-grid .project .project_description {
    width: 95%;
    /* border: white 3px solid; */
    text-align: left;
    margin-right:  0.7em;
    margin-left:  0.7em;
    text-overflow: ellipsis; 
    color: white;
    margin-bottom: 4em;
}

/* Actual description of the project */
.project-grid .project .project_description p {
    font-size: 1.3em;
    color: white;
}

/* Demo, Source Code, and Full Project Buttons */
.project-grid .project .project_Card .project_Information .infoButton {
    width: 95%;
    height: 2.5em;
    margin-top: 1em;
    background-color: #EFEFEF;
    border: 1px #767676 solid;
    transition: background-color 500ms ease, border-radius 500ms ease, color 500ms ease;
}


/* Hover event for informtion buttons */
.project-grid .project .project_Card .project_Information .infoButton:hover {
    background-color: #28A744;
    color: white;
    border: 1px solid white;
    border-radius: 15px;
    cursor: pointer;           
}


/* Focus events for informtion buttons */
.project-grid .project .project_Card .project_Information .infoButton:focus {
    outline: 0;
}



/* Active events for informtion buttons */
.project-grid .project .project_Card .project_Information .infoButton:active {
    color: black;
}


/* Media Events */
@media (max-width: 1400px) {
    .project-grid {
        grid-template-columns: repeat(3, auto);
    }
}

@media (max-width: 1200px) {
    .project-grid {
        grid-template-columns: repeat(2, auto);
    }
}

@media (max-width: 800px) {
    .project-grid {
        grid-template-columns: repeat(1, auto);
    }
}