.page_Content #name {
    font-size: 5em;
    text-align: center;
    margin: 0rem auto 1rem auto;
    padding-top: 5rem;
}

.page_Content .contact_icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.contact_icons li {
    display: inline;
    
}

.contact_icons img {
    height: 16em;
    width: 16em;
    padding: 2rem;
    transition: transform 500ms;
}

.contact_icons img:hover {
    transform: scale(1.3);
}

.contact_icons a:link, a:visited {
    text-decoration: none;
}