.page_Content .resume_container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100vw;
    height: auto;
}

.page_Content .resume_container .resume_section {   
    width: 100%;
    min-height: auto;
    margin: 0 auto;
    padding-top: 5em;
    /* border: 3px solid blue; */
}

.page_Content .resume_container #skills_Section {
    background-color: orange;
}

.page_Content .resume_container #education_section {
    background-color: lightblue;
}

.page_Content .resume_container #work_experience_section {
    background-color: lightcoral;
}

.page_Content .resume_container .resume_section .section_display {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: auto;
}

/* General Styles for all Sections */
.page_Content .resume_container .resume_section h1 {
    font-size: 2.5em;
    text-align: center;
    
}

.page_Content .resume_container .resume_section .underline {
    height: 2px;
    width: 7em;
    margin: 1em auto;
    background-color: black;
}

.page_Content .resume_container .resume_section h2 {
    font-size: 2em;
    font-weight: normal;
    text-decoration: underline;
    margin: 0rem 0rem 0.5rem 0rem;
}

.page_Content .resume_container .resume_section .section_display .left_side {
    text-align: center;
    width: 50%;
    min-height: auto;
    /* background-color: green;  */
    padding: 2rem 2rem;
    
}

.page_Content .resume_container .resume_section .section_display .right_side {
    text-align: center;
    width: 50%;
    min-height: auto;
    /* background-color: sienna; */
    padding: 2rem 4rem;
}



.page_Content .resume_container .resume_section .section_display ul {
    list-style-type: none;
    font-size: 1.5em;
    margin: 0rem 1rem 2rem 0rem;
    padding: 0rem;
}

.page_Content .resume_container .resume_section .section_display img {
    display: block;
    margin: auto;
    width: 100%;

}

/* Specific Styles to Education Section */
.page_Content .resume_container #education_section .section_display .right_side {
    text-align: left;
}

.page_Content .resume_container #education_section .section_display ul li {
    list-style-type: none;
    margin: 0rem 0rem 2rem 0rem;
    padding: 0rem;
}


/* Specific Styles to Work Experience Section */
.page_Content .resume_container #work_experience_section .section_display ul span {
    display: block;
}

.page_Content .resume_container #work_experience_section .section_display ul .first_work_experience {
    margin-top: 0rem;
}

.resume_container #work_experience_section .section_display ul li {
    margin-top: 1.5rem;
}


@media (max-width: 700px) {
    .resume_container .resume_section .section_display {
        flex-direction: column;
        align-items: center;   
    }
}

@media (max-width: 500px) {
    .resume_container .resume_section .section_display {
        flex-direction: column;
        align-items: center;   
    }
}

.page_Content .resume_container .fadeIn {
    opacity: 0;
}